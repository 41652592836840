import Vue from 'vue'
import App from './App.vue'
import VueScrollTo from 'vue-scrollto'
import 'tailwindcss/tailwind.css'

Vue.config.productionTip = false

Vue.use(VueScrollTo, {
  container: "body",
  duration: 800,
  easing: "ease",
  offset: -50,
});

new Vue({
  render: h => h(App),
}).$mount('#app')
