<template>
  <div >
    <div class="fixed z-1 w-full h-screen background">
    </div>
    <div class="flex justify-center w-full h-auto">
      <div class="relative w-full">
        <div class="fixed flex justify-center items-center w-screen bg-gray-50 h-12 z-50 shadow-md">
          <header-menu class="w-full sm:w-4/5" id="home"></header-menu>
        </div>
        <div name="sections" class="absolute flex justify-center flex-col items-center z-2 left-0" style="background: none;">
          <section class="h-full !mb-10 md:!mb-40">
             <resume></resume> 
          </section>
          <hr>
          <section id="projects" class="w-full ">
            <projects></projects>
          </section>
          <section id="technologies" class="w-full">
            <technologies/>
          </section>
          <section id="contact" class="w-full">
            <contact/>
          </section>
          <!-- <section id="hobbies">
            <hobbies/>
          </section> -->
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenu from '../components/headerMenu/HeaderMenu.vue'
import ResumeSection from './sections/resume/resumeSection.vue'
import ProjectsSection from './sections/projects/projects.vue'
import Technologies from './sections/technologies/technologies.vue'
import Contact from './sections/contact/contact.vue'
// import Hobbies from './sections/hobbies.vue'

export default {
  name: 'LandingPage',
  components: {
    HeaderMenu,
    Resume: ResumeSection,
    Projects: ProjectsSection,
    Technologies,
    Contact,
    // Hobbies
  }
}
</script>

<style scoped>
section {
  margin-bottom: 5rem;
}
.background{
  background: linear-gradient(to top, rgb(209, 209, 209) 0%, rgb(232, 232, 232) 100%);;
}
</style>