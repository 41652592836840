<template>
    <div>
        <h1 class="text-center mb-24">Technologies</h1>
        <div class="flex justify-center">
            <div class="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-6 m-4
                        w-4/5">
                <div v-for="tech in technologyList" :key="tech.id" 
                    class="box-container w-full aspect-square flex justify-center items-center" 
                    style="border-radius: 10%;box-shadow: 2px 4px 8px 0px gray; ">
                    <div
                    class="relative z-10 w-full h-full flex justify-center items-center"
                        style="border-radius: 10%; margin: 4px;">
                        <tech-icon 
                        :source="tech.source" 
                        :altTitle="tech.alt" 
                        :title="tech.title"
                        class="w-3/5 h-3/5"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Technologies} from "./technologyList.js"
import techIcon from "../../../components/bigIcon/bigIcon.vue"

export default {
    name: "technologies",
    components: {
        techIcon,
        // card
    },
    data(){
        return{
            technologyList: Technologies
        }
    }
}
</script>

<style scoped>
.box-container{
    position: relative;
}
.box-container::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10%;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: -3px -4px 8px 0px white;
    z-index: 1;
    transition: 0.5s;
}
</style>