export const ProjectList = [
    {
        source: "nexus.png", 
        techIcons: ["js.png", "vue.png"], 
        moreInfoPath: "https://www.alternova.co/projects/nexus-platform-remote-rct-platform/", 
        alt: "Nexus screenshot", title: "Nexus", 
        description: "Nexus is the control center for neurological research studies at universities and research centers such as the University of California (UCSF)."},
    {
        source: "colorama.png", 
        techIcons: ["js.png", "vue.png", "ts.png"], 
        moreInfoPath: "https://app-colorama.com/", 
        alt: "Colorama screenshot", title: "Colorama", 
        description: "A web tool to take a preview of a color palette in a template."},
    
    {
        source: "ace.png", 
        techIcons: ["csharp.png", "unity.png", "js.png"], 
        moreInfoPath: "https://www.alternova.co/projects/ace-explorer-cognitive-assessment-application/", 
        alt: "ACE screenshot", title: "ACE", 
        description: "This is an application dedicated to collecting data for neural research. It does this through different activities presented to the user as a mini-game adventure."},
    {
        source: "candleRush.png", 
        techIcons: ["csharp.png", "unity.png"], 
        moreInfoPath:"", 
        alt: "CandleRush screenshot", title: "Candle Rush", 
        description: "This is a video game born in a game jam. It has levels that increase the difficulty and will make you think to reach the goal."},
    {
        source: "meditrain.png", 
        techIcons: ["csharp.png"], 
        moreInfoPath:"https://www.alternova.co/projects/meditrain-meditation-training-for-adults/", 
        alt: "Meditrain screenshot", title: "Meditrain", 
        description: "This app helps users focus and avoid distractions. It provides achievable goals, feedback, and an adaptive algorithm that increases difficulty as users improve."},
    {
        source: "labyrinth.png", 
        techIcons: ["csharp.png"], 
        moreInfoPath:"https://www.alternova.co/projects/labyrinth-researching-alzheimers-and-dementia-prevention/", 
        alt: "Labyrinth screenshot", 
        title: "Labyrinth", 
        description: "A hybrid VR/tablet game offering spatial exploration of unfamiliar environments. This game stimulates brain networks crucial for learning, leading to improved memory and, potentially, broader cognitive enhancement."},
]