<template>
    <div class="w-full">
        <div class="flex flex-col items-center justify-center">
            <h1 class="text-center mb-24">Projects</h1>
            <div class="grid grid-cols-1 justify-items-center 
                md:grid-cols-2
                xl:grid-cols-3 
                2xl:grid-cols-4 
                gap-2
                gap-y-8
                md:gap-8
                xl:gap-8
                2xl:gap-28
                w-4/5 md:w-3/4">
                <div v-for="project in projects" :key="project.id">
                    <flippingCard
                        :title="project.title"
                        :imageName="project.source"
                        :altTitle="project.alt"
                        :techIcons="project.techIcons"
                        :description="project.description"
                        :moreInfoPath="project.moreInfoPath"
                    >
                    </flippingCard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ProjectList} from "./projectList.js";
import flippingCard from "@/components/flippingCard.vue";

export default{
    name: "projects",
    components:{
        flippingCard
    },
    data(){
        return{
            projects: ProjectList
        }
    },
    methods:{
        imageRoute(image){
            return require(`@/assets/images/projects/${image}`);
        },
        techIconRoute(icon){
            return require(`@/assets/icons/small/${icon}`);
        }
    }
}
</script>

<style scoped>
.card{
    overflow: hidden;
}
.card:hover{
    /* transform: scale(1.1);
    transition: transform 0.3s; */
    z-index: 4;
}
.card:hover .image{
    filter: blur(4px);
    transform: scale(1.2);
    transition: transform 0.3s;
}
.card:hover .tech-icon{
    color: rgba(227, 0, 0, 0);
    transition: color 0.3s;
}
.tech-icon{
    fill: rgba(227, 0, 0, 0);
    transition: color 0.3s;
}
.card:hover .div.title-container{
    color: green;
}
.card:hover .descriptor{
    background-color: rgba(0,0,0,0.5);
    transition: background-color 0.3s;
    color: white;;
}
.card:hover .descriptor div button{
    border: 1px solid white;
    transition: color 0.3s;
}
.card:hover .transparent-gradient{
    background: rgba(0,0,0,0);
}
.card:hover .title{
    color: rgba(0,0,0,0);
    transition: color 0.3s;
}
.title{
    color: white;
}
/* .image:hover{
    transform: scale(1.3);
    transition: transform 0.3s;
} */
.transparent-gradient{
    position: absolute; 
    z-index: 3;
    width: 100%; 
    height: 60px;
    background: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%);
}
.descriptor{
    position: absolute;
    background-color: rgba(0,0,0,0);
    z-index: 3;
    height: 100%;
    color: rgba(0,0,0,0);
}
.descriptor div button{
    border:none;
}
</style>