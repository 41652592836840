<template>
    <div class="w-full h-full">
        <div class="relative h-full z-4 min-[300px]:hidden max-[650]:visible">
            <img class="red  animate-in"
                :src="profilePicturePath" 
                alt="Profile Picture"
                :style="{'mask-position': '40% 10%', 'mask-size':'50%'}">
            <img class="w-full blue  animate-in" 
                :src="profilePicturePath" 
                alt="Profile Picture"
                :style="{'mask-position': '70% 15%', 'mask-size': '45%'}">
            <img class="w-full yellow  animate-in" 
                :src="profilePicturePath" 
                alt="Profile Picture"
                :style="{'mask-position': '55% 59%', 'mask-size': '40%'}">
            <img class="w-full red  animate-in" 
                :src="profilePicturePath" 
                alt="Profile Picture"
                :style="{'mask-position': '90% 60%', 'mask-size':'50%'}">
            <img class="w-full red animate-in"
                :src="profilePicturePath" 
                alt="Profile Picture"
                :style="{'mask-position': '50% 80%', 'mask-size':'20%'}">

            <img class="red animate hidden"
            :src="profilePicturePath" 
                alt="Profile Picture"
                :style="{'mask-position': '30% 20%'}">
            <img class="w-full blue animate hidden" 
                :src="profilePicturePath" 
                alt="Profile Picture"
                :style="{'mask-position': '60% 40%'}">
            <img class="w-full yellow animate hidden" 
                :src="profilePicturePath"  
                alt="Profile Picture"
                :style="{'mask-position': '80% 40%'}">
            <img class="red animate hidden"
                :src="profilePicturePath" 
                alt="Profile Picture"
                :style="{'mask-position': '30% 20%'}">
            <img class="w-full blue animate hidden" 
                :src="profilePicturePath" 
                alt="Profile Picture"
                :style="{'mask-position': '60% 40%'}">
            <img class="w-full yellow animate hidden"
                :src="profilePicturePath" 
                alt="Profile Picture"
                :style="{'mask-position': '80% 40%'}">
        </div>
    </div>
</template>

<script>

export default{
    name: "profilePicture",
    data(){
        return{
            profilePicturePath: require('@/assets/profile.png'),
            images: [],
            tempShowImages: [],
            tempHideImages: null,
            spanDropsInterval: 3000,
            fadeDropsDuration: 1500
        }
    },
    mounted(){
        this.findImages();
        setTimeout(() => {
            this.spanDrops();
        }, 300);
    },
    methods:{
        spanDrops(){
            if(this.tempShowImages.length == 0){
                this.tempShowImages = [...this.images];
            }
            if(this.tempHideImages != null && this.tempHideImages.length == 0){
                this.tempHideImages = [...this.images];
            }
            if(this.tempShowImages.length == this.images.length/2 && this.tempHideImages == null){
                this.tempHideImages = [...this.images];
            }
            let image = this.tempShowImages.pop();
            if(image.classList.contains('animate-out')){
                image.classList.remove('animate-out');
            }
            if(image.classList.contains('hidden')){
                image.classList.remove('hidden');
            }
            image.classList.add('animate-in');
            
            if(this.tempHideImages != null){
                let imageToHide = this.tempHideImages.pop();
                if(imageToHide.classList.contains('animate-in')){
                    imageToHide.classList.remove('animate-in');
                }
                imageToHide.classList.add('animate-out');
                setTimeout(() => {
                    imageToHide.classList.add('hidden')
                }, this.fadeDropsDuration - 20);
            }
            image.style.maskPosition = `${Math.floor(Math.random() * 100)}% ${Math.floor(Math.random() * 90)}%`;
            image.style.maskSize = `${Math.floor(Math.random() * 30) + 30}%`;

            setTimeout(() => {
                this.spanDrops();
            }, this.spanDropsInterval);
        },
        findImages(){
            this.images = document.getElementsByClassName('animate');
            this.images = Array.from(this.images);
        },
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
        }
    }
}

</script>

<style scoped>
@keyframes fadeIn {
    from {opacity: 0.1;}
    to {opacity: 1.0;}
}
@keyframes fadeOut {
    from {opacity: 1.0;}
    to {opacity: 0.1;}
}
img{
    width: 90%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    left: 50%;
    top: 50%;
    scale: 100%;
}
.animate-in {
    animation-name: fadeIn;
    animation-duration: 0.9s;
}
.animate-out {
    animation-name: fadeOut;
    animation-duration: 1.5s;
}
.red {
    position: absolute;
    z-index: 4;
    object-fit: cover;
    mask-image: url('../../../assets/images/sprites/drop_1.png');
    mask-mode: alpha;
    mask-repeat: no-repeat;
    mask-border: solid 19px black;
    mask-size: 30%;
}

.blue {
    position: absolute;
    z-index: 5;
    object-fit: cover;
    mask-image: url('../../../assets/images/sprites/drop_2.png');
    mask-mode: alpha;
    mask-repeat: no-repeat;
    mask-size: 50%;
}

.yellow {
    position: absolute;
    z-index: 5;
    object-fit: cover;
    mask-image: url('../../../assets/images/sprites/drop_3.png');
    mask-mode: alpha;
    mask-repeat: no-repeat;
    mask-size: 50%;
}
</style>