<template>
    <div>
      <button @click="toggleMenu" class="burger-icon" aria label="main menu button">
        <span></span>
        <span></span>
        <span></span>
      </button>
  
      <div v-if="isOpen" class="menu !bg-white">
        <div v-for="option in options" :key="option.id">
            <button v-scroll-to="option.path">
                <p class="text-xl">{{ option.label }}</p>
            </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      options: Array,
    },
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      toggleMenu() {
        this.isOpen = !this.isOpen;
      },
    },
  };
  </script>
  
  <style scoped>
  .burger-icon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }
  
  .burger-icon span {
    background-color: #333;
    height: 2px;
    width: 25px;
    margin: 3px 0;
    display: block;
  }
  
  .menu {
    background-color: #333;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    top: 60px;
    right: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .menu a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 10px;
    display: block;
  }
  </style>
  