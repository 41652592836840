<template>
  <div>
    <div class="flip-card w-72 aspect-[4/3]" @click="turnCard(`flip-card-inner-4${altTitle}`)">
      <div class="flip-card-inner" :id="`flip-card-inner-4${altTitle}`">
        <div class="flip-card-front">
          <img class="h-full" :src="imageRoute(imageName)" :alt="altTitle" loading="lazy"/>
        </div>
        <div class="flip-card-back">
          <p class="m-2">{{ description }}</p>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-between">
        <div class="title-primary text-primary text-left mt-2 text-2xl font-bold decoration-solid">{{ title }}</div>
        <div class="w-1/3 flex justify-end" style="filter: brightness(0.3);">
            <div v-for="icon in techIcons" :key="icon.id">
                <div class="mini-icon-container shadow-md mr-2 aspect-square w-8 h-8 items-center p-1" 
                  style="border-radius: 50%;">
                  <img :src="techIconRoute(icon)" :alt="icon" class="tech-icon w-4 h-4 m-1"
                    style="color: white;background-color: initial;" loading="lazy">
                </div>
            </div>
        </div>
    </div>
    <div class="flex" v-if="moreInfoPath != ''">
        <a :href="moreInfoPath" class="flex" style="text-align: start !important;" target="_blank">
            <div class="hover:underline decoration-solid disabled:opacity-75" aria-disabled="true">More Info</div>
        </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "flippingCard",
  props: {
    title: String,
    imageName: String,
    altTitle: String,
    description: String,
    techIcons: Array,
    moreInfoPath: String,
  },
  data() {
    return {
      flipped: false,
    };
  },
  methods: {
    imageRoute(image) {
      return require(`@/assets/images/projects/${image}`);
    },
    techIconRoute(icon){
        return require(`@/assets/icons/small/${icon}`);
    },
    turnCard(elementId) {
      var elemento = document.getElementById(elementId);
      this.flipped = !this.flipped;
      elemento.style.transform = this.flipped 
        ? "rotateY(180deg)"
        : "rotateY(0deg)";
    },
  },
};
</script>

<style scoped>
.mini-icon-container{
    transform: translate(0, 8px);
}
.flip-card {
  background-color: transparent;
  perspective: 1000px;
  font-family: sans-serif;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.flip-card:hover .flip-card-inner {
  @media (min-width: 767px) {
    transform: rotateY(180deg);
  }
}
.flip-card-front, .flip-card-back {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid #26f8d1;
  border-radius: 1rem;
  overflow: hidden;
}

.flip-card-front {
  background: linear-gradient(
    120deg,
    bisque 60%,
    rgb(255, 231, 222) 88%,
    rgb(255, 211, 195) 40%,
    rgba(255, 127, 80, 0.603) 48%
  );
  color: coral;
}
.flip-card-back {
  background: linear-gradient(
    140deg,
    #22b6ae ,
    #005450
  );
  color: white;
  transform: rotateY(180deg);
}
</style>