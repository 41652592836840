<template>
    <div class="flex flex-col justify-center h-full">
        <img 
        class="h-full object-scale-down mx-auto"
        :class="{'tech-icon': true, 'iconShadow': addShadow}" 
        :src="imageRoute(source)" 
        :alt="altTitle" loading="lazy">
        <p
        style="font-size: 0.7rem;"
        >{{ title }}</p>
    </div>
</template>

<script>
export default{
    name: "techIcon",
    props:{
        source: String,
        altTitle: String,
        title: String,
        addShadow: Boolean
    },
    methods:{
        imageRoute(image){
            return require(`@/assets/icons/${image}`);
        }
    }
}
</script>

<style scoped>
.tech-icon{
}
.iconShadow{
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
}
</style>