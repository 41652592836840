<template>
    <div>
        <h1>Contact</h1>
        <div class="flex justify-center">
            <div 
            class="
            w-4/5
            xl:w-3/5
            button
            grid 
            grid-cols-3
            sm:grid-cols-4 
            md:grid-cols-4
            lg:grid-cols-4 
            gap-x-12
            gap-y-12
            lg:-gap-x-10">
                <a v-for="channel in contactChannels" :key="channel.id"
                :href="channel.link" target="_blank"
                class="
                h-full
                md:h-2/3
                lg:h-2/5 w-full"
                    >
                    <big-icon 
                        class="h-full w-full"
                        :source="channel.source" 
                        :altTitle="channel.alt" 
                        :title="channel.title"
                        :addShadow="true"
                    />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import {ContactChannels} from "./contactChannels.js"
import bigIcon from "../../../components/bigIcon/bigIcon.vue"

export default{
    name: "contact",
    components:{
        bigIcon
    },
    data(){
        return{
            contactChannels: ContactChannels
        }
    }
}
</script>