<template>
    <div class="flex justify-between items-center h-full">
        <img src="@/assets/icons/logo.png" alt="Logo" class="h-5/6 aspect-square">
        <burguerMenu class="relative visible sm:hidden" :options="options"></burguerMenu>
        <div class="sm:grid grid-flow-col auto-cols-max justify-end gap-x-10 hidden">
            <div v-for="section in options" :key="section.label"
            >
                <button v-scroll-to="section.path">
                    <p class="text-xl">{{ section.label }}</p>
                </button>
            </div>        
        </div>
    </div>
</template>

<script>
import {options} from './utils.js'
import burguerMenu from '../burguerMenu.vue'

export default {
    name: 'HeaderMenu',
    components: {
        burguerMenu
    },
    data() {
        return {
            options: options
        }
    }
}
</script>

<style scoped> 
p{
    position: relative;
}
p::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #555;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}
p:hover::after{
    visibility: visible;
    transform: scaleX(1);
}
</style>