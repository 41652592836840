<template>
    <div class="flex flex-col md:flex-row items-center justify-center h-screen resume-container">
        <div name="profile-picture" class="w-4/5 md:w-1/2 h-1/4 md:h-full align-center  pr-0 md:pr-10">
            <profile-picture/>
        </div>
        <div class="text-left w-full md:w-3/5 px-8 md:p-0">
            <p class="text-xl">Hi! I'm</p>
            <div class="title-primary text-4xl md:text-6xl mt-4 md:mt-8">Julio César</div>
            <div class="title-secondary text-4xl md:text-6xl text-primary">Rodríguez</div>
            <p class="my-8 text-xl w-full md:w-3/4">
                I am passionate about technology and motivated to work in dynamic environments that foster my growth. I am committed to delivering high-quality work, analyzing for functionality and user experience, and proposing innovative solutions aligned with client objectives.
            </p>
            <div class="text-left">
                <a id="downloadFrontCVHolder" href="/docs/Julio_Cesar_Rodriguez_Frontend_CV.pdf"
                    download="Julio_Cesar_Rodriguez_Frontend_CV.pdf" style="display: none;">
                </a>
                <button class="shadow-md" style="background-color: #22b6ae" @click="downloadCV" aria-label="download cv">
                    Download CV
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import profilePicture from "./profilePicture.vue"

export default {
    name: 'ResumeSection',
    components: {
        profilePicture
    },
    methods: {
        downloadCV(){
            console.log("downloadCV");
            document.getElementById('downloadFrontCVHolder').click();
        }
    }
}
</script>

<style scoped>
.title-primary{
    font-weight: 700;
}
.title-secondary{
    font-weight: 700;
}
button{
    color: white;
    border-radius: 5px;
    padding: 7px;
}
h1{
    font-size: 60px;
    line-height: 1;
}

.resume-container{
    mask: linear-gradient(rgba(255,255,255,1) 85%, rgba(0,0,0,0) );
    background: url("@/assets/images/background/bg-1.png") center/cover no-repeat;
}
</style>